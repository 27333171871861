import styled, { css } from 'styled-components'
import { LinkProps } from '.'
import { media } from '../../../styles/breakpoints'
import { colors } from '../../../styles/colors'
import { Theme } from '../../../styles/theme-enum'

export interface ButtonComponentProps {
  cursorPointer: boolean,
  expanded: boolean,
  mobileWidth: string,
  desktopWidth: string,
  mobileHeight: string,
  desktopHeight: string,
  textUppercase: boolean,
  mobilePadding: string,
  desktopPadding: string
  theme: Theme,
}

export const themes = {
  [Theme.default]: css`
    color: #f7f7f7;
    background-color: #4E98F1;
    font-size: 12px;
    font-weight: 700;
    padding: 5px 24px;
    max-width: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 18px;

    ${media.tabMUp} {
      font-size: 22px;
      padding: 5px 0px;
      border-radius: 32px;
    }
  `,
  [Theme.light]: css`
    background-color: #222;
    color: #f7f7f7;
    font-size: 12px;
    font-weight: 700;
    padding: 13px 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 38px;
    min-height: 43px;
    margin: 0 auto;
    max-width: 310px;

    ${media.tabMUp} {
      font-size: 16px;
    }
  `,
  [Theme.dark]: css`
    color: #4E98F1;
    background-color: #fff;
    border-radius: 87px;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2.27788px 25.0567px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    padding: 0 15px;
    font-size: 12px;
    width: max-content;
    
    ${media.tabMUp} {
      width: max-content;
      padding: 0 35px;
      font-size: 28px;
    }
  `
}

export const ButtonComponent = styled.button<ButtonComponentProps>`
  font-family: 'Proxima Nova', sans-serif;
  border: none;
  outline: none;
  cursor: ${props => props.cursorPointer ? 'pointer' : ''};
  display: flex;
  flex-direction: row;
  width: ${({mobileWidth}) => mobileWidth != '' ? mobileWidth : '110px'};
  min-height: ${({mobileHeight}) => mobileHeight != '' ? mobileHeight : '25px'};
  max-height: ${({mobileHeight}) => mobileHeight != '' ? mobileHeight : '25px'};
  padding: ${({mobilePadding}) => mobilePadding != '' ? mobilePadding : ''} !important;

  ${media.tabMUp} {
    width: ${({desktopWidth}) => desktopWidth != '' ? desktopWidth : '300px'};
    min-height: ${({desktopHeight}) => desktopHeight != '' ? desktopHeight : '44px'};
    max-height: ${({desktopHeight}) => desktopHeight != '' ? desktopHeight : '44px'};
    padding: ${({desktopPadding}) => desktopPadding != '' ? desktopPadding : ''} !important;
  }
  
  ${props => themes[props.theme]};
`

export const LinkComponent = styled.a<LinkProps>`
  background-color: ${colors.irisBlue};
  font-size: 12px;
  color: white;
  text-transform: uppercase;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 16px;
  margin-left: 16px;
  border-radius: 30px;
  width: ${props => props.expanded ? '100%' : 'fit-content'};

  &:visited {
    color: white;
  }

  &:hover {
    background-color: ${colors.bondiBlue};
  }

  &:active {
    background-color: ${colors.cerulean};
    box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.25);
  }

  ${media.tabMUp} {
    font-size: 14px;
  }
`

export const Spacer = styled.div`
  width: 5px;
  height: 1px;
`
