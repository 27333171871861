import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import { ApiError } from '../errors/ApiError'
import { appStore } from '../stores/AppStore'

enum RequestHeaders {
  AUTHORIZATION = 'Authorization',
}

interface IEnvironments {
  baseUrl: string,
  baseTagsUrl: string,
  bearerToken: string,
}

export abstract class BaseService {
  private _client: AxiosInstance
  private _clientTags: AxiosInstance
  protected abstract environments: IEnvironments

  protected handleRequest(request: any) {
    return request
  }

  protected handleResponse(response: any) {
    return response
  }

  protected handleRequestError(error: AxiosError) {
    throw new ApiError(error.message)
  }

  protected handleResponseError(error: AxiosError) {
    throw new ApiError(
      error.response && error.response.data && error.response.data instanceof AxiosError
        ? error.response.data?.message
        : error.message
    )
  }

  protected get client() {
      const bearerToken = this.environments.bearerToken
      const authorization = bearerToken ? `Bearer ${bearerToken}` : null

      this._client = axios.create({
        baseURL: this.environments.baseUrl,
        headers: {
          [RequestHeaders.AUTHORIZATION]: authorization,
        },
      })
      
      this._clientTags = axios.create({
        baseURL: this.environments.baseTagsUrl,
        headers: {
          [RequestHeaders.AUTHORIZATION]: authorization,
        },
      })

      this._client.interceptors.request.use(this.handleRequest, this.handleRequestError)
      this._client.interceptors.response.use(this.handleResponse, this.handleResponseError)
    
      this._clientTags.interceptors.request.use(this.handleRequest, this.handleRequestError)
      this._clientTags.interceptors.response.use(this.handleResponse, this.handleResponseError)
    

    return this._client
  }
  
  protected get tagsClient() {
      const bearerToken = this.environments.bearerToken
      const authorization = bearerToken ? `Bearer ${bearerToken}` : null

      this._clientTags = axios.create({
        baseURL: this.environments.baseTagsUrl,
        headers: {
          [RequestHeaders.AUTHORIZATION]: authorization,
        },
      })

      this._clientTags.interceptors.request.use(this.handleRequest, this.handleRequestError)
      this._clientTags.interceptors.response.use(this.handleResponse, this.handleResponseError)
    

    return this._clientTags
  }

  async get<T>(path: string, config: AxiosRequestConfig = {}): Promise<T> {
    return this.client.get(path, config)
  }
  
  async getTags<T>(path: string, config: AxiosRequestConfig = {}): Promise<T> {
    return this.tagsClient.get(path, config)
  }
  
  async post<T>(path: string, params: any, config: AxiosRequestConfig = {}): Promise<T> {
    return this.client.post(path, params, config)
  }

  async put<T>(path: string, params: any, config: AxiosRequestConfig = {}): Promise<T> {
    return this.client.put(path, params, config)
  }
  
  async delete<T>(path: string, config: AxiosRequestConfig = {}): Promise<T> {
    return this.client.delete(path, config)
  }
}
