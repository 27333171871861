import { SVG } from '../Svg'

export const LogoIcon = props => (
  <SVG title="Persono" width="127" height="25" viewBox="0 0 127 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.6,0.9c-1.8,0-3.5,0.1-5,0.3C0.7,1.3,0,2,0,2.9v21.5h4.7v-7.2c0.5,0,1.4,0.1,2.1,0.1c5.5,0,10.2-2.4,10.2-8.1
C17,3.6,13,0.9,6.6,0.9z M6.4,13c-0.6,0-1.4,0-1.7-0.1V5.3C5.2,5.3,6,5.2,6.5,5.2c3.1,0,5.1,1.2,5.1,3.9C11.6,11.8,9.7,13,6.4,13z"
fill="black"	/>
<path d="M27.2,5.6c-2.7,0-4.8,0.9-6.5,2.6c-1.7,1.8-2.5,4-2.5,6.7c0,5.5,4,9.5,10,9.5c2.9,0,5.2-0.5,6.9-1.3l-1.1-4
	c-1.7,0.7-3.7,1.1-5.8,1.1c-2.7,0-4.6-1.6-4.8-3.8h12.3c0.1-0.6,0.1-1.2,0.1-1.7C35.7,9.2,32.8,5.6,27.2,5.6z M23.3,13.5
	c0.3-2.2,1.7-3.7,4-3.7c2.2,0,3.3,1.2,3.5,3.7H23.3z" fill="black"	/>
<path d="M48.7,5.8c-0.6-0.2-1.2-0.3-1.9-0.3c-2.5,0-5.1,0.6-7.7,1.8c-0.6,0.3-1,0.9-1,1.6v15.5h4.8V11.8c1.4-1.3,3.4-1.5,4.6-1.6
	L48.7,5.8z" fill="black"	/>
<path d="M60.5,13.3l-3.3-0.7c-1.6-0.3-2.3-0.7-2.3-1.5c0-0.9,1-1.6,2.7-1.6c2,0,3.9,0.5,5.8,1.4l1.1-3.9c-1.9-1-4.3-1.5-7-1.5
	c-4.5,0-7.6,2.1-7.6,5.7c0,2.7,1.5,4.4,4.5,5.1l3.1,0.6c1.9,0.4,2.9,1,2.9,1.8c0,0.9-1.1,1.7-2.9,1.7c-2.3,0-4.3-0.5-6.3-1.5l-1.1,4
	c1.8,0.9,5,1.5,7.3,1.5c5.2,0,8.1-2.1,8.1-5.7C65.5,15.5,63.9,14.1,60.5,13.3z" fill="black"	/>
<path d="M117,5.6c-5.2,0-9.4,4.2-9.4,9.4c0,5.2,4.2,9.4,9.4,9.4c5.2,0,9.4-4.2,9.4-9.4C126.4,9.8,122.2,5.6,117,5.6z M117,20.3
	c-2.6,0-4.7-2.4-4.7-5.3s2.1-5.3,4.7-5.3s4.7,2.4,4.7,5.3S119.6,20.3,117,20.3z" fill="black"	/>
<path d="M76.1,5.6c-5.2,0-9.4,4.2-9.4,9.4c0,5.2,4.2,9.4,9.4,9.4c5.2,0,9.4-4.2,9.4-9.4C85.5,9.8,81.3,5.6,76.1,5.6z M76.1,20.3
	c-2.6,0-4.7-2.4-4.7-5.3s2.1-5.3,4.7-5.3c2.6,0,4.7,2.4,4.7,5.3S78.7,20.3,76.1,20.3z" fill="black"	/>
<path d="M96.7,5.6c-2.5,0-5.1,0.6-7.8,1.8c-0.6,0.3-1.1,0.9-1.1,1.6v15.4h5.2V11.3c1.3-0.7,2.6-1,3.8-1c1.9,0,3.1,1.1,3.1,2.6v11.4
	h5.2V13.1C105.3,8,102,5.6,96.7,5.6z" fill="black"	/>
  </SVG>
)
