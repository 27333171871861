import { Theme } from '../../../styles/theme-enum'
import { ButtonComponent, ButtonComponentProps, LinkComponent, Spacer } from './styles'

export interface LinkProps {
  expanded: boolean,
  theme: Theme,
}

export interface ButtonProps {
  children?: string
  onClickFunction?: Function,
  expanded?: boolean
  textUppercase?: boolean,
  theme?: Theme,
  customWidth?: IResponsiveValues,
  customHeight?: IResponsiveValues,
  className?: string,
  customPadding?: IResponsiveValues
}

export interface IResponsiveValues {
  mobileValue: string,
  desktopValue: string,
}

export interface ButtonLinkProps {
  children: string,
  href: string,
  expanded?: boolean,
  icon?: React.ReactNode
}

export const ButtonLink = (props: ButtonLinkProps) => {
  return (
    <LinkComponent
      href={props.href}
      expanded={props.expanded}
    >
      <Spacer />
      {props.children}
      <Spacer />
    </LinkComponent>
  )
}

export const Button = (props: ButtonProps) => {
  const { onClickFunction, children, expanded, textUppercase, theme, customWidth, customHeight, customPadding, className } = props

  const buttonProps: ButtonComponentProps = {
    cursorPointer: onClickFunction != null,
    expanded: expanded ?? false,
    textUppercase: textUppercase ?? false,
    theme: theme ?? Theme.default,
    desktopWidth: customWidth?.desktopValue ?? '',
    mobileWidth: customWidth?.mobileValue ?? '',
    mobileHeight: customHeight?.mobileValue ?? '',
    desktopHeight: customHeight?.desktopValue ?? '',
    mobilePadding: customPadding?.mobileValue ?? '',
    desktopPadding: customPadding?.desktopValue ?? '',
  }

  return (
    <ButtonComponent
      className={className ?? ''}
      cursorPointer={buttonProps.cursorPointer}
      expanded={buttonProps.expanded}
      textUppercase={buttonProps.textUppercase}
      theme={buttonProps.theme}
      desktopWidth={buttonProps.desktopWidth}
      mobileWidth={buttonProps.mobileWidth}
      mobileHeight={buttonProps.mobileHeight}
      desktopHeight={buttonProps.desktopHeight}
      onClick={() => onClickFunction != null ? onClickFunction() : null}
      data-testid={`button-theme-${buttonProps.theme}`}
      mobilePadding={buttonProps.mobilePadding}
      desktopPadding={buttonProps.desktopPadding}
    >
      {children}
    </ButtonComponent>
  )
}