import styled, { css } from 'styled-components'

interface ISVGProps {
  title: string
  viewBox: string
  bolder?: boolean
  bolderValue?: number
  bolderSelector?: string
  width?: string
  height?: string
}

const SVGComponent = (({ children, title, bolder, bolderValue, bolderSelector, ...props }) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>{title}</title>
    {children}
  </svg>
))

export const SVG = styled(SVGComponent)`
  width: ${props => props.width ? props.width : '28px'};
  height: ${props => props.height ? props.height : '28px'};
  display: inline-block;
  fill: none;
`
