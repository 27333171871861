import * as Sentry from '@sentry/nextjs'
import { action, observable } from 'mobx'
import { LocalStorageKeys } from '../../../../public/static/localstorage-keys.enum'
import { PostEmailParameter } from '../../../services/interfaces/PersonoSensorAPIDtos'
import PersonoSensorAPIService from '../../../services/PersonoSensorAPIService'

class LeadStore {
  @observable email: string = ''
  @observable feedbackMessageKey: string = ''
  @observable isValidEmail: boolean = true
  @observable successSendEmail: boolean = false
  @observable closeLeads: boolean = false
  @observable localStorage?: Storage
  @observable apiService = PersonoSensorAPIService

  validateEmail = (email: string): boolean => {
    const reg = (/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    email = email.toLowerCase()
    return reg.test(email)
  }

  @action
  addEmail = (email: string): void => {
    this.email = email
    this.isValidEmail = true
    this.feedbackMessageKey = ''
  }

  @action
  sendEmail = async (language: string, fromModal: boolean, origin = 'BLOG'): Promise<void> => {
    this.isValidEmail = this.validateEmail(this.email)
    if (this.isValidEmail) {
      const data: PostEmailParameter = {
        email: this.email,
        language,
        fromModal,
        origin
      }

      try {
        await this.apiService.postEmail(data)
        this.feedbackMessageKey = 'email-successfuly-registered'
        this.email = ''
        this.successSendEmail = true
        this.closeLeadsModal()
      } catch (error) {
        Sentry.captureException(error)
        this.feedbackMessageKey = 'email-already-registered'
        this.isValidEmail = false
      }
    } else {
      this.feedbackMessageKey = 'invalid-email'
    }
  }

  @action
  closeLeadsModal = (): void => {
    try {
      this.localStorage.setItem(LocalStorageKeys.SHOULD_SHOW_MODAL, 'true')
      this.validateLocalStorage()
    } catch (e) {
      this.closeLeads = true
    }
  }

  @action 
  resetValues = (): void => {
    this.email = ''
    this.feedbackMessageKey = ''
    this.isValidEmail = true
    this.successSendEmail = false
    this.closeLeads = false
  }

  @action
  validateLocalStorage = (): void => {
    if (this.localStorage.length > 0) {
      const showModal = this.localStorage.getItem(LocalStorageKeys.SHOULD_SHOW_MODAL)
      this.closeLeads = showModal === 'true'
    }
  }
}

export default LeadStore