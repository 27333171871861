import Script from 'next/script'
import { IPostMeta } from '../services/interfaces/WordpressServiceDtos'

export const buildMeta = (metaInfo: IPostMeta[]) => {
  return metaInfo.map(meta => {

    let { content } = meta

    if (meta.property && !meta.property.includes('image')) {
      content = meta.content.replace('wordpress.persono.com.br', 'persono.com.br')
    }

    if (meta.name && meta.name.includes('robots')) {
      content = meta.content.replace('noindex', 'index')
    }

    return meta.name
      ? <meta name={meta.name} content={content} key={meta.name} />
      : <meta property={meta.property} content={content} key={meta.property} />
  })
}

export const buildMetaScript = (script: any[]) => {
  const replacedJson = JSON
    .stringify(script[0])
    .replace(/wordpress.persono.com.br/g, 'persono.com.br')
  const scriptJson = <Script type="application/ld+json" dangerouslySetInnerHTML={{ __html: replacedJson }} />

  return scriptJson
}