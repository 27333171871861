import { BaseService } from './BaseService'
import { 
  IGetArticleSurveyParams,
  IGetArticleSurveyResponse,
  IPostArticleSurveyParams,
  PostEmailParameter
} from './interfaces/PersonoSensorAPIDtos'

class PersonoSensorAPIService extends BaseService {
  protected environments = {
    baseUrl: process.env.PERSONO_SENSOR_API_BASEURL,
    bearerToken: process.env.PERSONO_SENSOR_API_BEARER_TOKEN,
    baseTagsUrl: process.env.WORDPRESS_PERSONO_BASEURL_TAGS,
  }

  async postEmail(data: PostEmailParameter) {
    const { email, language, fromModal, origin } = data
    return await this.post('/institucional-leads', { 
      email, 
      'language': language, 
      'fromModal': fromModal, 
      'origin': origin
    })
  }

  async getArticleSurveyAnswer(params: IGetArticleSurveyParams) {
    return await this.get<IGetArticleSurveyResponse>('/article-survey/institutional', { params })
  }

  async setArticleSurveyAnswer(params: IPostArticleSurveyParams) {
    const { userId, categorySlug, slug, like } = params
    return await this.post('/article-survey/institutional', { userId, categorySlug, slug, like })
  }
}

export default new PersonoSensorAPIService()