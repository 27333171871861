export const colors = {
  black: '#000000',
  irisBlue: '#00A8E0',
  bondiBlue: '#008FBF',
  cerulean: '#007BAB',
  gray20: '#333333',
  navyBlue: '#36ACEE',
  whiteSmoke: '#F4F4F4',
  gray10: '#F5F5F5',
  gray13: '#212121',
  gray94: '#F0F0F0',
}